<template>
  <transition name="slide-fade">
    <v-container class="mt-10">
      <v-row v-if='!initial_auth'>
        <v-col md="12" sm="4" >
          <div style="margin: auto; margin-top:80px; width:25%;">
            <div id="session-expired" v-if="sessionExpired">
              <span>{{loginmessage}}</span>
            </div>
            <v-img src="../assets/images/fnlogo.png" max-height="150" max-width="250"></v-img>

            <v-form @keyup.enter.native="login()" autocomplete="off" ref="form" v-model="valid"
              class="d-flex justify-space-around flex-wrap flex-column mb-4">
              <v-text-field autocomplete="username" class="input" v-model="username" label="Username" required>
              </v-text-field>

              <v-text-field autocomplete="new-password" class="input" v-model="password" type="password"
                label="Password" required></v-text-field>
              <v-btn color="#d7d7d7" class="mr-4" :loading="loadstate" @click="login">
                Log In
              </v-btn>

            </v-form>
            <br>


            <v-expand-transition>
              <div v-show="!valid">
                <v-alert class="ma-1" border="top" color="blue-grey" dark>
                  {{ error_message }}
                </v-alert>
              </div>

            </v-expand-transition>

          </div>

        </v-col>
        

        <br>
        <div style="width=100%; text-align:center; margin:auto;">
          <a v-bind:href="url">Forgot Password</a>
        </div>
      </v-row>
      <v-row v-if='initial_auth'>
        <v-col md="12" sm="5" >
          <div style="margin: auto; margin-top:40px; width:40%;">
            <v-img src="../assets/images/fnlogo.png" style="margin: auto;" max-height="150" max-width="250"></v-img>
            <div style="text-align: center; padding-top: 40px">
              <h5>2-step verification</h5>
              <p>An email with a six digit passcode has been sent.</p>
              <p>Please open the email and enter the code in the
                field below. This code will expire in 15 minutes.</p>
            </div>
          
          </div>
          <div style="margin: auto; width:25%; margin-bottom: 10px;">
            <v-form @keyup.enter.native="login()" autocomplete="off" ref="form" v-model="valid"
            class="d-flex justify-space-around flex-wrap flex-column mb-4">
            <br>
            <v-otp-input
            length="5"
            type="number"
            v-model="auth_code"
          ></v-otp-input>
          <br>
            <v-btn color="#d7d7d7" class="mr-4" :loading="authloadstate" @click="authenticate">
              Authenticate
            </v-btn> <br>
            <v-btn color="#d7d7d7" class="mr-4" :loading="loadstate" @click="login">
              Get new code
            </v-btn>

          </v-form>
          </div>

          <v-expand-transition>
            <div v-show="!auth_valid">
              <v-alert class="ma-1" border="top" color="blue-grey" dark>
                {{ auth_error_message }}
              </v-alert>
            </div>

          </v-expand-transition>
        </v-col>
        
      </v-row>
      <v-row>
        <v-col md="12">
          <div style="margin:auto; width:700px; text-align:center; display: block; padding-top: 40px;"> <div id="mobile-notice">
            Please note that our website is currently not optimized for mobile devices. We apologize for any inconvenience and appreciate your understanding.
          </div>
          <p>
              If you any questions or concerns, please call our Farmers National Company Help Desk at 877-255-2380, Monday
              through Friday, 8:00 to 5:00 CST.
            </p>
            <br/>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </transition>
</template>
<script>
import http from "../http-common";
export default {
  data: () => ({
    valid: true,
    auth_valid: true,
    username: "",
    password: "",
    loadstate: false,
    error_message: "",
    show: false,
    url: process.env.VUE_APP_SUGARURL + "portal/index.php?forgotpassword#forgotpassword",
    initial_auth: false,
    auth_code: "",
    authloadstate: false,
    auth_error_message: "",
    userIsLoggedIn: localStorage.getItem('userIsLoggedIn') === 'true',
    sessionExpired: false,
    loginmessage: ''
  }),

  methods: {
    login() {

      localStorage.removeItem('loginEvent');

      this.valid = true;
      this.loadstate = true;
      localStorage.removeItem("userdata");
      // Remove accounts from previous logins
      localStorage.removeItem("AccountsList");
      localStorage.removeItem("SelectedAccount")
      localStorage.setItem('logoutEvent', 'false'); 
      

      if (this.username == "" || this.password == "") {
        this.error_message = "Username and Password are Required.";
        this.valid = false;
        this.loadstate = false;
        return false;
      } else {
        var querystring = require("querystring");
        let data = querystring.stringify({
          username: this.username, //gave the values directly for testing
          password: this.password,
        });
        http.defaults.withCredentials = true;



        http
          .post("/validate", data, { withCredentials: true })
          .then((response) => {
            console.log('Success?', response)
            this.loadstate = false;

            this.userIsLoggedIn = true;
            localStorage.setItem('userIsLoggedIn', 'true');
            
            // Trigger a custom event to notify other tabs
            localStorage.setItem('loginEvent', Date.now().toString());

            console.log(response.data);

            this.show = false;
            if(response.data.status === "Success" && response.data.mfa_required){
              this.initial_auth = true
            }else{
              localStorage.setItem("AdminAuth", JSON.stringify(response.data.AdminAuth));
              localStorage.setItem("PortalAuth", JSON.stringify(response.data.PortalAuth));
              localStorage.setItem("PortalMe", JSON.stringify(response.data.PortalMe));
            
              // this.$router.push({ name: `home` });
              console.log('validate redirect')
              const currentURL = window.location.href;
              if(currentURL.includes('?')){

                const queryParamsString = currentURL.split('?')[1];
                const queryParamsArray = queryParamsString.split('&');
  
                const params = {};
                if(queryParamsArray.length){
                  queryParamsArray.forEach(param => {
                    const [key, value] = param.split('=');
                    params[key] = value;
                  });
                  console.log(params)
                }
                if(params.redirect === 'profile'){
                  console.log("params found")
                  window.location.href = '/profile';
                  return true;
                }
              }
              window.location.href = '/'
            }
            // localStorage.setItem("AdminAuth", JSON.stringify(response.data.AdminAuth));
            // localStorage.setItem("PortalAuth", JSON.stringify(response.data.PortalAuth));
            // localStorage.setItem("PortalMe", JSON.stringify(response.data.PortalMe));
            
            // this.$router.push({ name: `mfa` });

          })
          .catch((error_data) => {
            console.log(error_data.response.data);
            let error = error_data.response;

            this.error_message = error.data;
            if(error.status == "412"){
              this.error_message = "It looks like something failse, please try again."
            }
            if(error.status == "401"){
              this.error_message = "Invalid Username or Password."
            }

            this.error_message;
            this.loadstate = false;
            this.valid = false;
          })
      }
    },
    authenticate() {

      this.authloadstate = true;

      localStorage.removeItem("userdata");
      if(this.auth_code.length < 5){
        this.auth_error_message = "Invalid code";
        this.authloadstate = false;
        this.auth_valid = false;
        return false;
      }

      if (this.auth_code === "") {
        this.auth_error_message = "Authentication code can't be empty.";
        this.authloadstate = false;
        this.auth_valid = false;
        return false;
      } else {
        var querystring = require("querystring");
        let data = querystring.stringify({
          username: this.username, //gave the values directly for testing
          password: this.password,
          code: this.auth_code
        });
        http.defaults.withCredentials = true;



        http
          .post("/authenticate", data, { withCredentials: true })
          .then((response) => {
            this.authloadstate = false;
            console.log("mfa_response", response.data);


            localStorage.setItem("AdminAuth", JSON.stringify(response.data.AdminAuth));
            localStorage.setItem("PortalAuth", JSON.stringify(response.data.PortalAuth));
            localStorage.setItem("PortalMe", JSON.stringify(response.data.PortalMe));
            
            // this.$router.push({ name: `home` });
            const currentURL = window.location.href;
              if(currentURL.includes('?')){

                const queryParamsString = currentURL.split('?')[1];
                const queryParamsArray = queryParamsString.split('&');
  
                const params = {};
                if(queryParamsArray.length){
                  queryParamsArray.forEach(param => {
                    const [key, value] = param.split('=');
                    params[key] = value;
                  });
                  console.log(params)
                }
                if(params.redirect === 'profile'){
                  console.log("params found")
                  window.location.href = '/profile';
                  return true;
                }
              }
            window.location.href = "/";

          })
          .catch((error_data) => {
            // console.log(error_data.response.data);
            let error = error_data.toJSON();
            this.auth_valid = false;
            this.auth_error_message = error.status === 401 ? "Invalid Credentials" : error.message;
            this.authloadstate = false;
          });
      }
    },
  },
  created(){
    window.addEventListener('storage', (event) => {
        if (event.key === 'loginEvent') {
            // If a login event is detected in local storage, refresh the page
            // this.$router.push('/');
            window.location.href = '/';

        }
    });
  },
  mounted() {
    console.log(this.$route.query.session)
    if(this.$route.query.session === 'expired'){
      this.sessionExpired = true;
      this.loginmessage = 'Your session expired, please login again.'
    }

    if(this.$route.query.redirect === 'profile'){
      this.sessionExpired = true;
      this.loginmessage = 'Please Login to Continue.'
    }

    this.show = true;
    document.body.style.backgroundColor = "#ffffff";
    localStorage.removeItem('lastActivityTimestamp');
  }
};
</script>
<style scoped>
.input {
  text-align: center;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .6s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}
#mobile-notice{
  padding: 1rem;
  border-radius: 5px;
  border: solid 1px gray;
  box-shadow: 5px 5px 5px #c2c2c2;
  margin: 3em 0px;
}

#session-expired{
  padding: 1rem;
  background-color: #22AE46;
  color: #ffffff;
  border-radius: 5px;
  box-shadow: 5px 5px 5px #c2c2c2;
  font-size: 14px;
  margin-bottom: 2rem;
}
</style>